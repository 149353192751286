import React from "react"
import styles from "../css/contact.module.css"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const Contact = () => {
  const intl = useIntl()
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = e => {
    if (e.target.files[0].size / 1024 / 1024 > 1) {
      alert("La taille du fichier dépasse 1 MB")
      e.target.value = ""
    } else if (
      e.target.files[0].type !== "image/jpeg" &&
      e.target.files[0].type !== "image/png"
    ) {
      alert("Le fichier doit être au format jpeg, jpg ou png.")
      e.target.value = ""
    } else {
      setState({ ...state, [e.target.name]: e.target.files[0] })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <section id="contact" className="first">
      <div className={(styles.banner += " pv4")}>
        <h1 className={styles.titre}>
          <FormattedMessage id="contact.title-private1" />
          <br /> <FormattedMessage id="contact.title-private2" />
        </h1>
      </div>

      <div className="w-75-ns center mv6 ph3">
        <p>
          Contactez nous, nous réalisons tout vos projets de{" "}
          <Link to="/">vitrail art déco</Link>
        </p>
        <form
          className="flex flex-column mt4"
          name="Contact-Particulier"
          method="POST"
          action="/merci"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="Contact-Particulier" />
          <input type="hidden" name="bot-field" />
          <div className="flex justify-start-ns justify-center mv3">
            <span>
              <FormattedMessage id="contact.gender" />
            </span>
            <div className="mh3">
              <label htmlFor="monsieur">
                <input
                  type="radio"
                  id="monsieur"
                  name="Civilité"
                  value="Mr."
                  onChange={handleChange}
                  required
                />
                <FormattedMessage id="contact.monsieur" />
              </label>
            </div>
            <div>
              <label htmlFor="madame">
                <input
                  type="radio"
                  id="madame"
                  name="Civilité"
                  value="Mme."
                  onChange={handleChange}
                />
                <FormattedMessage id="contact.madame" />
              </label>
            </div>
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="nom"
              id="nom"
              placeholder={intl.formatMessage({ id: "contact.nom" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="prenom"
              id="prenom"
              placeholder={intl.formatMessage({ id: "contact.prenom" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="email"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="mail"
              placeholder={intl.formatMessage({ id: "contact.email" })}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="telephone"
              placeholder={intl.formatMessage({ id: "contact.phone" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="adresse"
              placeholder={intl.formatMessage({ id: "contact.adress" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="ville"
              placeholder={intl.formatMessage({ id: "contact.city" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="code postal"
              placeholder={intl.formatMessage({ id: "contact.zip" })}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              className="w-40-ns w-100 mv3 mv0-ns"
              name="pays"
              placeholder={intl.formatMessage({ id: "contact.country" })}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center mv3">
            <select
              className="w-40-ns w-100 w-70 mv3 mv0-ns"
              name="projet"
              id="projet"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                {intl.formatMessage({ id: "contact.project-type.default" })}
              </option>
              <option value="Porte d'entrée">
                {intl.formatMessage({ id: "contact.project-type.opt1" })}
              </option>
              <option value="Fenêtre">
                {intl.formatMessage({ id: "contact.project-type.opt2" })}
              </option>
              <option value="Verrière">
                {intl.formatMessage({ id: "contact.project-type.opt3" })}
              </option>
              <option value="Véranda">
                {intl.formatMessage({ id: "contact.project-type.opt4" })}
              </option>
            </select>

            <select
              className="w-40-ns w-100 w-70 mv3 mv0-ns"
              name="collection"
              id="collection"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                {intl.formatMessage({ id: "contact.collections.default" })}
              </option>
              <option value="Collection classique">
                {intl.formatMessage({ id: "contact.collections.opt1" })}
              </option>
              <option value="Collection intemporelle">
                {intl.formatMessage({ id: "contact.collections.opt2" })}
              </option>
              <option value="Collection art déco">
                {intl.formatMessage({ id: "contact.collections.opt3" })}
              </option>
              <option value="Collection moderne">
                {intl.formatMessage({ id: "contact.collections.opt4" })}
              </option>
            </select>
          </div>

          <div className="flex flex-wrap justify-between-ns justify-center items-center mv3">
            <div className="w-40-ns mv3 mv0-ns">
              <p>
                <FormattedMessage id="contact.dimensions.content" /> :
              </p>
              <div className="flex flex-wrap justify-between-ns justify-center">
                <input
                  type="number"
                  name="largeure"
                  placeholder={intl.formatMessage({
                    id: "contact.dimensions.width",
                  })}
                  className="w-40-ns w-100 mv3 mr2"
                  onChange={handleChange}
                />
                <input
                  type="number"
                  name="hauteur"
                  placeholder={intl.formatMessage({
                    id: "contact.dimensions.height",
                  })}
                  className="w-40-ns w-100 mv3 mr2"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div
              className={(styles.file += " w-40-ns w-100 mv3 mv0-ns")}
              style={{ overflow: "hidden" }}
            >
              <input
                type="file"
                name="photo"
                id="photo"
                onChange={handleAttachment}
              />
              <small className="db">
                <i className="fas fa-paperclip"></i>{" "}
                <FormattedMessage id="contact.file.content" />
              </small>
            </div>
          </div>

          <textarea
            className="mv3"
            rows="10"
            name="informations complémentaires"
            placeholder={intl.formatMessage({ id: "contact.message" })}
            onChange={handleChange}
            required
          ></textarea>

          <p>
            <small>
              <FormattedMessage id="contact.required" />
            </small>
          </p>

          <button className="mv3" type="submit">
            <FormattedMessage id="contact.submit" />
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact
