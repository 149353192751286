import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Contact from "../components/particulier/js/contact"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const ParticulierPage = () => (
  <>
    <SEO title="« Contactez votre artisan vitrail art deco pour particulier" />
    <Header />
    <Overlay />
    <Contact />
    <Footer />
    <Copyrights />
  </>
)

export default ParticulierPage
